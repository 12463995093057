var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      class: ["link-button", { "link-button--disabled": _vm.disabled }],
      attrs: { type: "button", "aria-label": _vm.title }
    },
    [
      _vm._t("prepend"),
      _vm._v(" "),
      _c("span", { domProps: { textContent: _vm._s(_vm.title) } }),
      _vm._v(" "),
      _vm._t("append")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }